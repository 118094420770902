//multi-langue
$(document).ready(function () {
  $(".lang-flag").click(function () {
    $(".language-dropdown").toggleClass("open");
  });
  $("ul.lang-list li").click(function () {
    $("ul.lang-list li").removeClass("selected");
    $(this).addClass("selected");
    if ($(this).hasClass('lang-fr')) {
      $(".language-dropdown").find(".lang-flag").addClass("lang-fr").removeClass("lang-en");
    } else {
      $(".language-dropdown").find(".lang-flag").addClass("lang-en").removeClass("lang-fr");
    }
    $(".language-dropdown").removeClass("open");
  });
})


//slider home
$('#owl-slider-home').owlCarousel({
  loop: true,
  margin: 0,
  items: 1,
  stagePadding: 0,
  singleItem: true,
})


//slider product
var $owl = $('#owl-product');

$owl.children().each( function( index ) {
  $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
});

$owl.owlCarousel({
  nav:true,
  margin: 100,
  center: true,
  loop: true,
  items: 7,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:false,
            loop: true,
        },
        769:{
            items:3,
            nav:false,
            loop: true,
        },
        1000:{
            items:3,
            nav:false,
            loop: true,
        },
        1200:{
          items:5,
          nav:true,
          loop: true,
      },
      1500:{
        items:7,
        nav:true,
        loop: true,
    }
    }

});

$(document).on('click', '.owl-item>div', function() {
  $owl.trigger('to.owl.carousel', $(this).data( 'position' ) );
});


//Listing product page
$(function () {
  var selectedClass = "";
  $(".fil-cat").click(function () {
    $('.fil-cat').removeClass("active");
    $(this).addClass("active");
    selectedClass = $(this).attr("data-rel");

    $("#list-products").fadeTo(100, 0.1);
    $(".one-product").not("." + selectedClass).fadeOut().removeClass('scale-anm');
    setTimeout(function () {
      $("." + selectedClass).fadeIn().addClass('scale-anm');
      $("#list-products").fadeTo(300, 1);
    }, 300);

  });
});


//Page product
$('.item-fonctionnement').on('click', function (e) {

  $('.conteneur-caracteristiques').hide();
  $('.item-caracteristique').removeClass('active');
  $('.conteneur-fonctionnement').show();
  $('.item-fonctionnement').addClass('active');

});

$('.item-caracteristique').on('click', function (e) {

  $('.conteneur-fonctionnement').hide();
  $('.item-fonctionnement').removeClass('active');
  $('.item-fonctionnement').remove('active');
  $('.conteneur-caracteristiques').show();
  $('.item-caracteristique').addClass('active');

});
